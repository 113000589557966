import React from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'rebass';
import Spinner from '../../../components/Spinner';
import { useFirebaseAuth } from '../../../components/FirebaseAuth';
import UpdateEmail from '../../../components/Members/Account/UpdateEmail';
import GateKeeper from '../../../components/FirebaseAuth/GateKeeper';
import UpdatePassword from '../../../components/Members/Account/UpdatePassword';
import UpdatePersonalInfo from '../../../components/Members/Account/UpdatePersonalInfo';
import MembershipInfo from '../../../components/Members/Account/MembershipInfo';
import MembersLayout from '../../../components/Members/Layout';
import Container from '../../../components/Container';
import useBbshMember from '../../../utils/hooks/members/useBbshMember';
import SEO from '../../../components/seo';

export const InputGroup = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
`;

const MemberAccount = () => {
  const { user, logout } = useFirebaseAuth();
  const { member, isLoading } = useBbshMember();
  return (
    <GateKeeper>
      <SEO title="Account settings" />
      <MembersLayout goBackLink="/members" title="Account settings">
        <Container py={[3, 4]}>
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <InputGroup my={3} m={-2}>
                <Box p={2} width={1}>
                  <UpdateEmail user={user} logout={logout} />
                </Box>
                <Box p={2} width={1}>
                  <UpdatePassword user={user} />
                </Box>
                <Box p={2} width={1}>
                  <UpdatePersonalInfo member={member} />
                </Box>
                <Box p={2} width={1}>
                  <MembershipInfo member={member} />
                </Box>
              </InputGroup>
            </>
          )}
        </Container>
      </MembersLayout>
    </GateKeeper>
  );
};

export default MemberAccount;
