import React from 'react';
import { Formik, Form, Field } from 'formik';
import Input from '../../Input';
import Select from '../../Select';
import Text from '../../Text';
import Button from '../../Button';
import { InputGroup } from '../../../pages/members/account';
import { Box } from 'rebass';
import AccountCard from './Card';
import { FiAward } from 'react-icons/fi';
import Alert from '../../Alert';
import ReactDatePicker from 'react-datepicker';
import useMembershipLevels from '../../../utils/hooks/members/useMembershipLevels';
import useUpdateMember from '../../../utils/hooks/members/useUpdateMember';

const MembershipInfo = ({ member }) => {
  const [mutation, { data, isLoading, error }] = useUpdateMember(member?.uid);
  const { data: membershipLevels } = useMembershipLevels();
  const success = data && !error;
  const isAdmin = member?.isAdmin;
  return (
    <AccountCard title="Membership" icon={<FiAward size={24} />}>
      <Formik initialValues={member} onSubmit={mutation}>
        <Form>
          <InputGroup m={-2}>
            <Box p={2}>
              <Field name="membershipExpires">
                {({ field, form }) => (
                  <Input
                    as={ReactDatePicker}
                    required
                    disabled={!isAdmin}
                    mr={3}
                    label="Membersip expires"
                    type="date"
                    {...field}
                    selected={
                      field.value ? new Date(field.value.toDate()) : new Date()
                    }
                    onSelect={e =>
                      form.setFieldValue('membershipExpires', e.toISOString())
                    }
                  />
                )}
              </Field>
            </Box>
            <Box p={2}>
              <Text variant="secondary" mb={2}>
                <label htmlFor="subject-select">Membership level</label>
              </Text>
              <Field name="membershipLevel">
                {({ field }) => (
                  <Select
                    width={1}
                    disabled={!isAdmin}
                    mr={3}
                    label="Membership level"
                    required
                    {...field}
                  >
                    {membershipLevels?.docs?.map(doc => (
                      <option key={doc.id} value={doc.id}>
                        {doc.data().label}
                      </option>
                    ))}
                  </Select>
                )}
              </Field>
            </Box>
            {isAdmin && (
              <Button disabled={isLoading} m={2}>
                {isLoading ? 'Updating...' : 'Update'}
              </Button>
            )}
          </InputGroup>
          {success && (
            <Alert mt={2} type="success">
              Membership info updated successfully
            </Alert>
          )}
          {error && (
            <Alert mt={2} type="danger">
              {error?.message ||
                'An unexpected error occurred. Try again later or contact us.'}
            </Alert>
          )}
        </Form>
      </Formik>
    </AccountCard>
  );
};

MembershipInfo.propTypes = {};

export default MembershipInfo;
