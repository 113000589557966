import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../../Button';
import Text from '../../Text';
import { firebaseApp } from '../../../firebase/config';
import AccountCard from './Card';
import { FiLock } from 'react-icons/fi';
import Alert from '../../Alert';

const UpdatePassword = ({ user }) => {
  const [success, setSuccess] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [isLoading, setLoading] = useState(false);
  const onSubmit = () => {
    setLoading(true);
    firebaseApp
      .auth()
      .sendPasswordResetEmail(user?.email)
      .then(() => {
        setLoading(false);
        setSuccess(true);
      })
      .catch(e => {
        setLoading(false);
        setSuccess(false);
        setError(e);
      });
  };
  if (!user) {
    return null;
  }
  return (
    <AccountCard title="Password" icon={<FiLock size={24} />}>
      <Text variant="secondary" mb={3}>
        To change your password, click on the button below. You will receive an
        email with instructions.
      </Text>
      {success ? (
        <Alert type="success">
          Password reset sent! Please, check your email
        </Alert>
      ) : (
        <Button disabled={isLoading} onClick={onSubmit}>
          {isLoading ? 'Sending...' : 'Send password reset email'}
        </Button>
      )}
      {error && (
        <Alert type="danger">
          {error?.message || error || 'An unexpected error occurred.'}
        </Alert>
      )}
    </AccountCard>
  );
};

UpdatePassword.propTypes = {
  user: PropTypes.object.isRequired,
};

export default UpdatePassword;
