import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import Button from '../../Button';
import { Box } from 'rebass';
import Input from '../../Input';
import { FiMail } from 'react-icons/fi';
import AccountCard from './Card';
import EmailVerification from '../../FirebaseAuth/EmailVerification';
import Alert from '../../Alert';

const UpdateEmail = ({ user, logout }) => {
  const [success, setSuccess] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [isLoading, setLoading] = useState(false);
  const onSubmit = ({ email }) => {
    setSuccess(false);
    setError(undefined);
    setLoading(true);
    user
      .updateEmail(email)
      .then(() => {
        user.sendEmailVerification().then(() => {
          setLoading(false);
          setSuccess(true);
        });
      })
      .catch(e => {
        setLoading(false);
        setSuccess(false);
        setError(e);
      });
  };
  if (!user) {
    return null;
  }
  return (
    <AccountCard title="Email address" icon={<FiMail size={24} />}>
      <Formik initialValues={{ email: user?.email }} onSubmit={onSubmit}>
        <Form>
          <Box mb={3}>
            <Field name="email">
              {({ field }) => (
                <Input mr={2} label="Email address" type="email" {...field} />
              )}
            </Field>
          </Box>
          <Button disabled={isLoading} type="submit">
            {isLoading ? 'Updating...' : 'Update'}
          </Button>
          {error && (
            <Alert mt={3} type="danger">
              {error?.code === 'auth/requires-recent-login' ? (
                <span>
                  Please{' '}
                  <a href="#!" onClick={logout}>
                    re-authenticate
                  </a>{' '}
                  and try again
                </span>
              ) : (
                error?.message || 'Something went wrong, please try again later'
              )}
            </Alert>
          )}
          {success && (
            <Alert type="success" mt={3}>
              Just one more step: please, check your email for the verification
              link
            </Alert>
          )}
        </Form>
      </Formik>
      {!success && <EmailVerification user={user} />}
    </AccountCard>
  );
};

UpdateEmail.propTypes = {
  user: PropTypes.object.isRequired,
  logout: PropTypes.object.isRequired,
};

export default UpdateEmail;
