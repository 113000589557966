import React, { useState } from 'react';
import { Box } from 'rebass';
import Input from '../../Input';
import Button from '../../Button';
import { Formik, Form, Field } from 'formik';
import firebase from '../../../firebase/config';
import { InputGroup } from '../../../pages/members/account';
import AccountCard from './Card';
import { FiUser } from 'react-icons/fi';
import Alert from '../../Alert';

const UpdatePersonalInfo = ({ member }) => {
  const [error, setError] = useState(undefined);
  const [success, setSuccess] = useState(undefined);
  const [isMutationLoading, setLoading] = useState(false);
  const isAdmin = member?.isAdmin;
  const onUpdatePersonalInfo = data => {
    setSuccess(false);
    setError(undefined);
    setLoading(true);
    const fn = firebase?.functions().httpsCallable('updateMember');
    fn({ uid: member.uid, ...data })
      .then(() => {
        setSuccess(true);
        setLoading(false);
      })
      .catch(e => {
        setError(e);
        setLoading(false);
      });
  };
  return (
    <AccountCard title="Personal info" icon={<FiUser size={24} />}>
      <Formik onSubmit={onUpdatePersonalInfo} initialValues={member}>
        <Form>
          <InputGroup alignItems="flex-start" m={-2}>
            <Box p={2}>
              <Field name="firstName">
                {({ field }) => (
                  <Input
                    mr={3}
                    disabled={!isAdmin}
                    required
                    label="First name"
                    {...field}
                  />
                )}
              </Field>
            </Box>
            <Box p={2}>
              <Field name="lastName">
                {({ field }) => (
                  <Input
                    mr={3}
                    disabled={!isAdmin}
                    required
                    label="Last name"
                    {...field}
                  />
                )}
              </Field>
            </Box>
            {isAdmin && (
              <Button disabled={isMutationLoading} m={2}>
                {isMutationLoading ? 'Updating...' : 'Update'}
              </Button>
            )}
          </InputGroup>
          {success && (
            <Alert mt={2} type="success">
              Personal info updated successfully
            </Alert>
          )}
          {error && (
            <Alert mt={2} type="danger">
              {error?.message ||
                'An unexpected error occurred. Try again later or contact us.'}
            </Alert>
          )}
        </Form>
      </Formik>
    </AccountCard>
  );
};

UpdatePersonalInfo.propTypes = {};

export default UpdatePersonalInfo;
