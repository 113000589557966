import React from 'react';
import PropTypes from 'prop-types';
import Card from '../../Card';
import { Flex } from 'rebass';
import Text from '../../Text';

const AccountCard = ({ title, icon, children, ...props }) => {
  return (
    <Card
      p={4}
      css={`
        box-shadow: 0 0 25.69px 0 rgba(0, 0, 0, 0.04),
          0 0 29.51px 0 rgba(76, 75, 94, 0.12), 0 0 32px 0 rgba(0, 0, 0, 0.03);
      `}
      {...props}
    >
      <Flex mb={3} alignItems="center">
        {icon}
        <Text fontSize={2} ml={icon ? 2 : undefined}>
          {title}
        </Text>
      </Flex>
      {children}
    </Card>
  );
};

AccountCard.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.node,
  children: PropTypes.node,
};

export default AccountCard;
